import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useCookies } from 'react-cookie';
import { useRouter } from 'src/routes/hook';

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
  auth0: paths.auth.auth0.login,
  amplify: paths.auth.amplify.login,
  firebase: paths.auth.firebase.login,
};

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const router = useRouter();
  const [cookies, setCookie] = useCookies(['authToken']);

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!cookies.authToken) {
      router.replace(paths.auth.login);
    } else {
      setChecked(true);
    }
  }, [cookies, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
