import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { data } from '../../utils/graphql-queries/persons/get-queries/get-persons';

export interface PersonTableProps {
  tableData?: data[];
  fromSelected?: number;
  toSelected?: number;
}

const initialState: PersonTableProps = {
  tableData: [],
  fromSelected: 0,
  toSelected: 0,
};

export const PersonTableSlice = createSlice({
  name: 'personTable',
  initialState,
  reducers: {
    addTableData: (state, action: PayloadAction<number>) => {
      state.fromSelected = action.payload;
    },
    addFromSelected: (state, action: PayloadAction<number>) => {
      state.fromSelected = action.payload;
    },
    addToSelected: (state, action: PayloadAction<number>) => {
      state.toSelected = action.payload;
    },
  },
});

export const { addFromSelected, addToSelected } = PersonTableSlice.actions;
export default PersonTableSlice.reducer;