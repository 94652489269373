import { Box } from '@mui/material';
import React from 'react';

interface props {
  children: React.ReactNode;
}

export default function NotFoundLayout({ children }: props) {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
}
