import { gql, TypedDocumentNode } from "@apollo/client";

interface data {
    createCity: {
        success: boolean,
        message: string
    }
}

export const CreateCarCity: TypedDocumentNode<data> = gql`
    mutation CreateCarCity ($name: String!) {
        createCity(body: { name: $name }) {
            success
            message
        }
    }
`;