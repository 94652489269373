import React from 'react';
import { FieldValues, useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import FormProvider, { RHFTextField } from "../../../../components/hook-form";

const CreateCarAdvertisementForm = () => {

    const methods = useForm<FieldValues>();
    const { handleSubmit } = methods;

    const onSubmit = async (data: FieldValues) => {

    };

    return (
        <FormProvider methods={ methods } onSubmit={ handleSubmit(onSubmit) }>
            <Grid container spacing={ 3 }>
                <Grid xs={ 12 } lg={ 6 }>
                    <RHFTextField type='number' name='year' label='Year'/>
                </Grid>

                <Grid xs={ 12 } lg={ 6 }>
                    <RHFTextField type='number' name='kilometers' label='Kilometer'/>
                </Grid>

                <Grid xs={ 12 }>
                    <RHFTextField name='plan' label='Kilometer'/>
                </Grid>

                <Grid xs={ 12 }>
                    <RHFTextField name='city' label='Kilometer'/>
                </Grid>

                <Grid xs={ 12 }>
                    <TextField
                        variant='filled'
                        rows={ 4 }
                        fullWidth
                        multiline
                        name='description'
                        label="description"
                        placeholder='Write Description Here...'
                    />
                </Grid>
            </Grid>

            <Stack sx={ { mt: 2 } }>
                <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    loading={ false }
                    variant="contained"
                    sx={ { justifyContent: 'space-between', pl: 2, pr: 1.5 } }
                >
                    Create
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
};

export default CreateCarAdvertisementForm;