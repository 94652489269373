import React from 'react';
// @MUI
import { Container } from '@mui/system';
// COMPONENTS
import { useSettingsContext } from 'src/components/settings';
import { Grid, Typography } from '@mui/material';
import AnalyticsWidgetSummary from 'src/sections/overview/analytics/analytics-widget-summary';
import AppNewInvoice from 'src/sections/overview/app/app-new-invoice';
import { _appInvoices, _bookings, _ecommerceSalesOverview } from 'src/_mock';
import AnalyticsWebsiteVisits from 'src/sections/overview/analytics/analytics-website-visits';
import BookingDetails from 'src/sections/overview/booking/booking-details';
import EcommerceCurrentBalance from 'src/sections/overview/e-commerce/ecommerce-current-balance';
import EcommerceSalesOverview from 'src/sections/overview/e-commerce/ecommerce-sales-overview';

export default function DashboardView() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Typography
        variant="h4"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        Hi, Welcome back 👋
      </Typography>

      <Grid container sx={{ gap: 3 }}>
        <Grid xs={12} sm={5.7} md={2.8}>
          <AnalyticsWidgetSummary
            title="Monthly Incoming"
            total={714000}
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
          />
        </Grid>

        <Grid xs={12} sm={5.7} md={2.8}>
          <AnalyticsWidgetSummary
            title="New Users"
            total={1352831}
            color="info"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />}
          />
        </Grid>

        <Grid xs={12} sm={5.7} md={2.8}>
          <AnalyticsWidgetSummary
            title="Total Invoices"
            total={1723315}
            color="warning"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
          />
        </Grid>

        <Grid xs={12} sm={5.7} md={2.8}>
          <AnalyticsWidgetSummary
            title="Bug Reports"
            total={234}
            color="error"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
          />
        </Grid>
      </Grid>

      <Grid container mt={3} gap={3}>
        <Grid xs={12} md={8.9}>
          <AnalyticsWebsiteVisits
            title="Monthly Incoming"
            subheader="(+43%) than last year"
            chart={{
              labels: [
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
              ],
              series: [
                {
                  name: 'Sept',
                  type: 'area',
                  fill: 'gradient',
                  data: [
                    231122, 273722, 213044, 223011, 222713, 223721, 442230, 231122, 273722, 213044,
                    223011, 222713, 223721,
                  ],
                },
                {
                  name: 'Aug',
                  type: 'line',
                  fill: 'gradient',
                  data: [
                    445541, 672243, 214156, 274344, 554167, 224321, 415627, 434455, 416722, 432143,
                    214156, 274344, 554167, 224321, 415627, 434455, 416722, 432143, 214156, 274344,
                    554167, 224321, 415627, 434455, 416722, 432143, 214156, 274344, 554167, 224321,
                  ],
                },
                {
                  name: 'July',
                  type: 'line',
                  fill: 'gradient',
                  data: [
                    302536, 304536, 453564, 525936, 393025, 363045, 356452, 593630, 253630, 453539,
                    302536, 304536, 453564, 525936, 393025, 363045, 356452, 593630, 253630, 453539,
                    302536, 304536, 453564, 525936, 393025, 363045, 356452, 593630, 253630, 453539,
                  ],
                },
              ],
            }}
          />
        </Grid>

        <Grid xs={12} lg={2.8}>
          <EcommerceCurrentBalance
            title="Incoming Overview"
            currentBalance={187650}
            sentAmount={25500}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ gap: 3 }} mt={3}>
        <Grid xs={12} md={8.9}>
          <AppNewInvoice
            title="Last Invoices"
            tableData={_appInvoices}
            tableLabels={[
              { id: 'id', label: 'Invoice ID' },
              { id: 'price', label: 'Invoice Amount' },
              { id: 'createDate', label: 'CreateDate' },
              { id: 'userInfo', label: 'User Info' },
              { id: 'status', label: 'Status' },
              { id: '' },
            ]}
          />
        </Grid>

        <Grid xs={12} lg={2.8}>
          <EcommerceSalesOverview title="Incoming Overview" data={_ecommerceSalesOverview} />
        </Grid>
      </Grid>

      <Grid xs={12} mt={3}>
        <BookingDetails
          title="Last Blog posts"
          tableData={_bookings}
          tableLabels={[
            { id: 'destination', label: 'Destination' },
            { id: 'customer', label: 'Customer' },
            { id: 'checkIn', label: 'Check In' },
            { id: 'checkOut', label: 'Check Out' },
            { id: 'status', label: 'Status' },
            { id: '' },
          ]}
        />
      </Grid>
    </Container>
  );
}
