import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSliceProps {
  phoneNumber: string;
}

const initialState: UserSliceProps = {
  phoneNumber: ''
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    savePhoneNumber: (state, actions: PayloadAction<{ phoneNumber: string }>) => {
      state.phoneNumber = actions.payload.phoneNumber;
    }
  }
});

export const { savePhoneNumber } = UserSlice.actions;
export default UserSlice.reducer;