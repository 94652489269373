import React from 'react';
import { Helmet } from "react-helmet-async";
import CreateCar from "./sections/create-car";

const CreateCarPage = () => (
    <>
        <Helmet>
            create car
        </Helmet>

        <CreateCar/>
    </>
);

export default CreateCarPage;