import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AddPhone {
  fileName?: string | undefined;
  phonesData: PhoneBook[]
}

export interface PhoneBook {
  fullName: string;
  phoneNumber: string;
  reference?: string;
  source?: string;
}

const initialState: AddPhone = {
  fileName: undefined,
  phonesData: []
}

export const AddPersonSlice = createSlice({
  name: 'addPhone',
  initialState,
  reducers: {
    addPhoneToPhoneBook: (state, actions: PayloadAction<AddPhone>) => {
      state.fileName = actions.payload.fileName;
      state.phonesData = actions.payload.phonesData;
    },
    removePhones: (state) => {
      state.fileName = undefined;
      state.phonesData = [];
    }
  }
});

export const { addPhoneToPhoneBook, removePhones } = AddPersonSlice.actions;
export default AddPersonSlice.reducer;