import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useMutation } from '@apollo/client';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { CustomToastMessage } from '../../../../utils/functions/custom-toast-message';
import { CreateCarCompany } from '../../../../utils/graphql-queries/car/mutations/create-car-company';

const CreateCarCompanyForm = () => {
  const methods = useForm<FieldValues>();
  const { handleSubmit } = methods;

  const [createMakeCompany, { loading }] = useMutation(CreateCarCompany);

  const onSubmit = async (data: FieldValues) => {
    try {
      const res = await createMakeCompany({ variables: data });

      if (res?.data?.createMakeCompony.success) {
        CustomToastMessage('success', res?.data?.createMakeCompony?.message);
      }
    } catch (e: any) {
      CustomToastMessage('error', e.message);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="name" label="Company Name" />

        <Stack sx={{ mt: 2 }}>
          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
          >
            Create
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default CreateCarCompanyForm;
