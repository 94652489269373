import React from 'react';
import { Helmet } from "react-helmet-async";
import CreateCarCity from "./sections/create-car-city";

const CreateCarCityPage = () => (
    <>
        <Helmet>
            create car City
        </Helmet>

        <CreateCarCity />
    </>
);

export default CreateCarCityPage;