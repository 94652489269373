// UTILS
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';
// config
// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  ROOT: '/',
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/',
  CLASSIFIED_ADS: '/classified-ads',
  ADS_MANAGEMENT: '/ads-management',
  PAYMENT_PLAN: '/payment-plan',
  USERS: '/users',
};

// ----------------------------------------------------------------------

export const paths = {
  home: 'https://salecar.pttfco.com',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  user: {
    root: `${ROOTS.USERS}`,
    new: `${ROOTS.USERS}/new`,
    list: `${ROOTS.USERS}`,
    cards: `${ROOTS.USERS}/cards`,
    profile: `${ROOTS.USERS}/profile`,
    account: `${ROOTS.USERS}/account`,
    edit: (id: string) => `${ROOTS.USERS}/${id}/edit`,
    demo: {
      edit: `${ROOTS.USERS}/${MOCK_ID}/edit`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.ROOT}/invoices`,
      new: `${ROOTS.ROOT}/invoices/new`,
      details: (id: string) => `${ROOTS.ROOT}/invoices/${id}`,
      edit: (id: string) => `${ROOTS.ROOT}/invoices/${id}/edit`,
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
  adsManagement: {
    root: `${ROOTS.ADS_MANAGEMENT}`,
    create: `${ROOTS.ADS_MANAGEMENT}/create`,
    edit: (id: string) => `${ROOTS.ADS_MANAGEMENT}/${id}/edit`,
    details: (id: string) => `${ROOTS.ADS_MANAGEMENT}/${id}/details`,
  },
  paymentPlan: {
    list: `${ROOTS.PAYMENT_PLAN}`,
    create: `${ROOTS.PAYMENT_PLAN}/create`,
    edit: (id: string) => `${ROOTS.PAYMENT_PLAN}/${id}/edit`,
    payment: (id: string) => `${ROOTS.PAYMENT_PLAN}/${id}/payment`,
  },
  classifiedADs: {
    make: {
      list: `${ROOTS.CLASSIFIED_ADS}/make`,
      create: `${ROOTS.CLASSIFIED_ADS}/make/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/make/${slug}/edit`,
    },
    model: {
      list: `${ROOTS.CLASSIFIED_ADS}/model`,
      create: `${ROOTS.CLASSIFIED_ADS}/model/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/model/${slug}/edit`,
    },
    trim: {
      list: `${ROOTS.CLASSIFIED_ADS}/trim`,
      create: `${ROOTS.CLASSIFIED_ADS}/trim/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/trim/${slug}/edit`,
    },
    keyword: {
      list: `${ROOTS.CLASSIFIED_ADS}/keyword`,
      create: `${ROOTS.CLASSIFIED_ADS}/keyword/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/keyword/${slug}/edit`,
    },
    type: {
      list: `${ROOTS.CLASSIFIED_ADS}/type`,
      create: `${ROOTS.CLASSIFIED_ADS}/type/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/type/${slug}/edit`,
    },
    year: {
      list: `${ROOTS.CLASSIFIED_ADS}/year`,
      create: `${ROOTS.CLASSIFIED_ADS}/year/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/year/${slug}/edit`,
    },
    city: {
      list: `${ROOTS.CLASSIFIED_ADS}/city`,
      create: `${ROOTS.CLASSIFIED_ADS}/city/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/city/${slug}/edit`,
    },
    country: {
      list: `${ROOTS.CLASSIFIED_ADS}/country`,
      create: `${ROOTS.CLASSIFIED_ADS}/country/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/country/${slug}/edit`,
    },
    condition: {
      list: `${ROOTS.CLASSIFIED_ADS}/condition`,
      create: `${ROOTS.CLASSIFIED_ADS}/condition/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/condition/${slug}/edit`,
    },
    exteriorColor: {
      list: `${ROOTS.CLASSIFIED_ADS}/exterior-color`,
      create: `${ROOTS.CLASSIFIED_ADS}/exterior-color/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/exterior-color/${slug}/edit`,
    },
    interiorColor: {
      list: `${ROOTS.CLASSIFIED_ADS}/interior-color`,
      create: `${ROOTS.CLASSIFIED_ADS}/interior-color/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/interior-color/${slug}/edit`,
    },
    horsePower: {
      list: `${ROOTS.CLASSIFIED_ADS}/horse-power`,
      create: `${ROOTS.CLASSIFIED_ADS}/horse-power/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/horse-power/${slug}/edit`,
    },
    extraFeature: {
      list: `${ROOTS.CLASSIFIED_ADS}/extra-feature`,
      create: `${ROOTS.CLASSIFIED_ADS}/extra-feature/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/extra-feature/${slug}/edit`,
    },
    bodyType: {
      list: `${ROOTS.CLASSIFIED_ADS}/bodyType`,
      create: `${ROOTS.CLASSIFIED_ADS}/bodyType/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/bodyType/${slug}/edit`,
    },
    transmission: {
      list: `${ROOTS.CLASSIFIED_ADS}/transmission`,
      create: `${ROOTS.CLASSIFIED_ADS}/transmission/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/transmission/${slug}/edit`,
    },
    technicalFeature: {
      list: `${ROOTS.CLASSIFIED_ADS}/technical-feature`,
      create: `${ROOTS.CLASSIFIED_ADS}/technical-feature/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/technical-feature/${slug}/edit`,
    },
    engineCapacity: {
      list: `${ROOTS.CLASSIFIED_ADS}/engine-capacity`,
      create: `${ROOTS.CLASSIFIED_ADS}/engine-capacity/create`,
      edit: (slug: string) => `${ROOTS.CLASSIFIED_ADS}/engine-capacity/${slug}/edit`,
    },
  },
};
