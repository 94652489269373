import React from 'react';
import { Helmet } from "react-helmet-async";
import CreateCarCompany from './sections/create-car-company';

const CreateCarCompanyPage = () => (
    <>
        <Helmet>
            create car company
        </Helmet>

        <CreateCarCompany />
    </>
);

export default CreateCarCompanyPage;