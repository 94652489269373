import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// COMPONENTS
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('mage--dashboard-2-fill'),
  message: icon('mage--message-dots-fill'),
  phone: icon('mage--phone-call-fill'),
  phoneBook: icon('mage--user-square-fill'),
  report: icon('mage--chart-fill'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t(''),
        items: [
          {
            title: t('dashboard'),
            path: '/',
            icon: ICONS.dashboard,
          },
          {
            title: t('invoices'),
            path: '/invoices',
            icon: ICONS.report,
          },
          {
            title: t('ads management'),
            path: '/ads-management',
            icon: ICONS.report,
          },
          {
            title: t('payment plan'),
            path: '/payment-plan',
            icon: ICONS.report,
          },
          {
            title: t('classified ads'),
            path: '/classified-ads',
            icon: ICONS.message,
            children: [
              { title: t('makes'), path: '/classified-ads/make' },
              { title: t('models'), path: '/classified-ads/model' },
              { title: t('trims'), path: '/classified-ads/trim' },
              { title: t('countries'), path: '/classified-ads/country' },
              { title: t('cities'), path: '/classified-ads/city' },
              { title: t('keywords'), path: '/classified-ads/keyword' },
              { title: t('conditions'), path: '/classified-ads/condition' },
              { title: t('years'), path: '/classified-ads/year' },
              { title: t('body types'), path: '/classified-ads/bodyType' },
              { title: t('transmissions'), path: '/classified-ads/transmission' },
              { title: t('engine capacities'), path: '/classified-ads/engine-capacity' },
              { title: t('horse power'), path: '/classified-ads/horse-power' },
              { title: t('exterior colors'), path: '/classified-ads/exterior-color' },
              { title: t('interior colors'), path: '/classified-ads/interior-color' },
              { title: t('technical features'), path: '/classified-ads/technical-feature' },
              { title: t('extra features'), path: '/classified-ads/extra-feature' },
            ],
          },
          {
            title: t('users'),
            path: '/users',
            icon: ICONS.phoneBook,
            children: [
              { title: t('list'), path: '/users' },
              { title: t('privilege level'), path: '/settings/privilege-level' },
            ],
          },
          {
            title: t('settings'),
            path: '/settings',
            icon: ICONS.message,
            children: [{ title: t('general'), path: '/settings/general' }],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
