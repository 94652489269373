import { Bounce, toast } from 'react-toastify';

export function CustomToastMessage(type: string, message: string, time: number = 1700) {
  if (type === 'success') {
    return toast.success(message, {
      position: 'top-center',
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  }
  return (
    toast.error(message, {
      position: 'top-center',
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    })
  );
}