import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        ...authRoutes,
        ...dashboardRoutes,
        { path: '*', element: <Navigate to="/404" replace/> },
    ]);
}
