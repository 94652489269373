import { Helmet } from 'react-helmet-async';
// sections
import DashboardView from './view/dashboard-view';

// ----------------------------------------------------------------------

export default function OverviewAppPage() {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <DashboardView />
    </>
  );
}
