import { gql, TypedDocumentNode } from '@apollo/client';

import { Profile } from 'src/types/profile';

interface getProfileData {
  getProfile: {
    data: Profile;
    success: string;
    message: string;
  };
}

export const Get_Profile: TypedDocumentNode<getProfileData> = gql`
  query getProfile {
    getProfile {
      firstName
      lastName
      email
      username
      phoneNumber
    }
  }
`;
