import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// layouts
import DashboardLayout from 'src/layouts/dashboard';
import NotFoundLayout from 'src/layouts/error/NotFoundLayout';
// COMPONENTS
import NotFoundPage from 'src/pages/404';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import OverviewAppPage from 'src/pages/dashboard/app';
import CreateCarAdvertisementPage from '../../pages/manage-car/create-car-advertisement/page';
import CreateCarCityPage from '../../pages/manage-car/create-car-city/page';
import CreateCarCompanyPage from '../../pages/manage-car/create-car-company/page';
import CreateCarPage from '../../pages/manage-car/create-car/page';

// GROUPS
const AddGroupPage = lazy(() => import('../../pages/group/add-group-page'));

// PHONE BOOK
const PersonsPage = lazy(() => import('../../pages/persons/page'));
const AddPersonPage = lazy(() => import('../../pages/persons/add-person-page'));

// SMS CAMPAIGN
const AddSmsCampaignPage = lazy(
  () => import('../../pages/dashboard/campaign/sms/add-sms-campaign-page')
);

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// USER
const UserProfilePage = lazy(() => import('src/pages/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/user/cards'));
const UserListPage = lazy(() => import('src/pages/user/list/page'));
const UserAccountPage = lazy(() => import('src/pages/user/account'));
const UserCreatePage = lazy(() => import('src/pages/user/create/page'));
const UserEditPage = lazy(() => import('src/pages/user/edit'));
// CLASSIFIED_ADS
const PlanListPage = lazy(() => import('src/pages/payment-plan/page'));
const CreatePlanPage = lazy(() => import('src/pages/payment-plan/create/page'));
const PlanEditPage = lazy(() => import('src/pages/payment-plan/edit/page'));
const HorsePowerListPage = lazy(() => import('src/pages/classified-ads/horse-power/page'));
const CreateHorsePowerPage = lazy(() => import('src/pages/classified-ads/horse-power/create/page'));
const HorsePowerEditPage = lazy(() => import('src/pages/classified-ads/horse-power/edit/page'));
const MakeListPage = lazy(() => import('src/pages/classified-ads/make/page'));
const CreateMakePage = lazy(() => import('src/pages/classified-ads/make/create/page'));
const MakeEditPage = lazy(() => import('src/pages/classified-ads/make/edit/page'));
const ModelListPage = lazy(() => import('src/pages/classified-ads/model/page'));
const CreateModelPage = lazy(() => import('src/pages/classified-ads/model/create/page'));
const ModelEditPage = lazy(() => import('src/pages/classified-ads/model/edit/page'));
const TrimListPage = lazy(() => import('src/pages/classified-ads/trim/page'));
const CreateTrimPage = lazy(() => import('src/pages/classified-ads/trim/create/page'));
const TrimEditPage = lazy(() => import('src/pages/classified-ads/trim/edit/page'));
const KeywordListPage = lazy(() => import('src/pages/classified-ads/keyword/page'));
const CreateKeywordPage = lazy(() => import('src/pages/classified-ads/keyword/create/page'));
const KeywordEditPage = lazy(() => import('src/pages/classified-ads/keyword/edit/page'));
const YearListPage = lazy(() => import('src/pages/classified-ads/year/page'));
const CreateYearPage = lazy(() => import('src/pages/classified-ads/year/create/page'));
const YearEditPage = lazy(() => import('src/pages/classified-ads/year/edit/page'));
const CityListPage = lazy(() => import('src/pages/classified-ads/city/page'));
const CreateCityPage = lazy(() => import('src/pages/classified-ads/city/create/page'));
const CityEditPage = lazy(() => import('src/pages/classified-ads/city/edit/page'));
const CountryListPage = lazy(() => import('src/pages/classified-ads/country/page'));
const CreateCountryPage = lazy(() => import('src/pages/classified-ads/country/create/page'));
const CountryEditPage = lazy(() => import('src/pages/classified-ads/country/edit/page'));
const ConditionListPage = lazy(() => import('src/pages/classified-ads/condition/page'));
const CreateConditionPage = lazy(() => import('src/pages/classified-ads/condition/create/page'));
const ConditionEditPage = lazy(() => import('src/pages/classified-ads/condition/edit/page'));
const ExteriorColorListPage = lazy(() => import('src/pages/classified-ads/exterior-color/page'));
const CreateExteriorColorPage = lazy(
  () => import('src/pages/classified-ads/exterior-color/create/page')
);
const ExteriorColorEditPage = lazy(
  () => import('src/pages/classified-ads/exterior-color/edit/page')
);
const InteriorColorListPage = lazy(() => import('src/pages/classified-ads/interior-color/page'));
const CreateInteriorColorPage = lazy(
  () => import('src/pages/classified-ads/interior-color/create/page')
);
const InteriorColorEditPage = lazy(
  () => import('src/pages/classified-ads/interior-color/edit/page')
);
const ExtraFeatureListPage = lazy(() => import('src/pages/classified-ads/extra-feature/page'));
const CreateExtraFeaturePage = lazy(
  () => import('src/pages/classified-ads/extra-feature/create/page')
);
const ExtraFeatureEditPage = lazy(() => import('src/pages/classified-ads/extra-feature/edit/page'));
const BodyTypeListPage = lazy(() => import('src/pages/classified-ads/body-type/page'));
const CreateBodyTypePage = lazy(() => import('src/pages/classified-ads/body-type/create/page'));
const BodyTypeEditPage = lazy(() => import('src/pages/classified-ads/body-type/edit/page'));
const TransmissionListPage = lazy(() => import('src/pages/classified-ads/transmission/page'));
const CreateTransmissionPage = lazy(
  () => import('src/pages/classified-ads/transmission/create/page')
);
const TransmissionEditPage = lazy(() => import('src/pages/classified-ads/transmission/edit/page'));
const TechnicalFeatureListPage = lazy(
  () => import('src/pages/classified-ads/technical-feature/page')
);
const CreateTechnicalFeature = lazy(
  () => import('src/pages/classified-ads/technical-feature/create/page')
);
const TechnicalFeatureEditPage = lazy(
  () => import('src/pages/classified-ads/technical-feature/edit/page')
);
const EngineCapacityListPage = lazy(() => import('src/pages/classified-ads/engine-capacity/page'));
const CreateEngineCapacityPage = lazy(
  () => import('src/pages/classified-ads/engine-capacity/create/page')
);
const EngineCapacityEditPage = lazy(
  () => import('src/pages/classified-ads/engine-capacity/edit/page')
);
// ADS MANAGEMENT
const AdsListPage = lazy(() => import('src/pages/ads-management/list/page'));
const CreateAdsPage = lazy(() => import('src/pages/ads-management/create/page'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/invoices/page'));
// const InvoiceDetailsPage = lazy(() => import('src/pages/invoices/details'));
// const InvoiceCreatePage = lazy(() => import('src/pages/invoice/new'));
// const InvoiceEditPage = lazy(() => import('src/pages/invoices/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <OverviewAppPage />, index: true },
      { element: <CreateCarPage />, path: 'create-car' },
      { element: <CreateCarCompanyPage />, path: 'create-car-company' },
      { element: <CreateCarCityPage />, path: 'create-car-city' },
      { element: <CreateCarAdvertisementPage />, path: 'create-car-advertising' },
      {
        path: 'invoices',
        children: [
          { element: <InvoiceListPage />, index: true },
          // { path: ':id', element: <InvoiceDetailsPage /> },
          // { path: ':id/edit', element: <InvoiceEditPage /> },
          // { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'payment-plan',
        children: [
          { element: <PlanListPage />, index: true },
          { path: 'create', element: <CreatePlanPage /> },
          { path: ':slug/edit', element: <PlanEditPage /> },
        ],
      },
      {
        path: '/ads-management',
        children: [
          { element: <AdsListPage />, index: true },
          { path: 'create', element: <CreateAdsPage /> },
        ],
      },
      {
        path: 'classified-ads',
        children: [
          {
            path: 'horse-power',
            children: [
              { element: <HorsePowerListPage />, index: true },
              { path: 'create', element: <CreateHorsePowerPage /> },
              { path: ':slug/edit', element: <HorsePowerEditPage /> },
            ],
          },
          {
            path: 'make',
            children: [
              { element: <MakeListPage />, index: true },
              { path: 'create', element: <CreateMakePage /> },
              { path: ':slug/edit', element: <MakeEditPage /> },
            ],
          },
          {
            path: 'model',
            children: [
              { element: <ModelListPage />, index: true },
              { path: 'create', element: <CreateModelPage /> },
              { path: ':slug/edit', element: <ModelEditPage /> },
            ],
          },
          {
            path: 'trim',
            children: [
              { element: <TrimListPage />, index: true },
              { path: 'create', element: <CreateTrimPage /> },
              { path: ':slug/edit', element: <TrimEditPage /> },
            ],
          },
          {
            path: 'keyword',
            children: [
              { element: <KeywordListPage />, index: true },
              { path: 'create', element: <CreateKeywordPage /> },
              { path: ':slug/edit', element: <KeywordEditPage /> },
            ],
          },
          {
            path: 'year',
            children: [
              { element: <YearListPage />, index: true },
              { path: 'create', element: <CreateYearPage /> },
              { path: ':slug/edit', element: <YearEditPage /> },
            ],
          },
          {
            path: 'city',
            children: [
              { element: <CityListPage />, index: true },
              { path: 'create', element: <CreateCityPage /> },
              { path: ':slug/edit', element: <CityEditPage /> },
            ],
          },
          {
            path: 'country',
            children: [
              { element: <CountryListPage />, index: true },
              { path: 'create', element: <CreateCountryPage /> },
              { path: ':slug/edit', element: <CountryEditPage /> },
            ],
          },
          {
            path: 'condition',
            children: [
              { element: <ConditionListPage />, index: true },
              { path: 'create', element: <CreateConditionPage /> },
              { path: ':slug/edit', element: <ConditionEditPage /> },
            ],
          },
          {
            path: 'exterior-color',
            children: [
              { element: <ExteriorColorListPage />, index: true },
              { path: 'create', element: <CreateExteriorColorPage /> },
              { path: ':slug/edit', element: <ExteriorColorEditPage /> },
            ],
          },
          {
            path: 'interior-color',
            children: [
              { element: <InteriorColorListPage />, index: true },
              { path: 'create', element: <CreateInteriorColorPage /> },
              { path: ':slug/edit', element: <InteriorColorEditPage /> },
            ],
          },
          {
            path: 'extra-feature',
            children: [
              { element: <ExtraFeatureListPage />, index: true },
              { path: 'create', element: <CreateExtraFeaturePage /> },
              { path: ':slug/edit', element: <ExtraFeatureEditPage /> },
            ],
          },
          {
            path: 'bodyType',
            children: [
              { element: <BodyTypeListPage />, index: true },
              { path: 'create', element: <CreateBodyTypePage /> },
              { path: ':slug/edit', element: <BodyTypeEditPage /> },
            ],
          },
          {
            path: 'transmission',
            children: [
              { element: <TransmissionListPage />, index: true },
              { path: 'create', element: <CreateTransmissionPage /> },
              { path: ':slug/edit', element: <TransmissionEditPage /> },
            ],
          },
          {
            path: 'technical-feature',
            children: [
              { element: <TechnicalFeatureListPage />, index: true },
              { path: 'create', element: <CreateTechnicalFeature /> },
              { path: ':slug/edit', element: <TechnicalFeatureEditPage /> },
            ],
          },
          {
            path: 'engine-capacity',
            children: [
              { element: <EngineCapacityListPage />, index: true },
              { path: 'create', element: <CreateEngineCapacityPage /> },
              { path: ':slug/edit', element: <EngineCapacityEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
    ],
  },

  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },

      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
    ],
  },
  {
    path: '/404',
    element: (
      <NotFoundLayout>
        <Suspense fallback={<LoadingScreen />}>
          <NotFoundPage />
        </Suspense>
      </NotFoundLayout>
    ),
  },
];
