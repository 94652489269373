import React from 'react';
import { Helmet } from 'react-helmet-async';
import CreateCarAdvertisement from './sections/create-car-advertisement';

const CreateCarAdvertisementPage = () => (
    <>
        <Helmet>
            Create Car Advertisement
        </Helmet>

        <CreateCarAdvertisement/>
    </>
);

export default CreateCarAdvertisementPage;