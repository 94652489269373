import { m } from 'framer-motion';
// @MUI
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// ROUTES
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// HOOKS
import { useCookies } from 'react-cookie';
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// COMPONENTS
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { usePopover } from 'src/components/custom-popover';
import { Button, Drawer, MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
    icon: (
      <Iconify icon="solar:home-2-bold-duotone" width={24} height={24} sx={{ opacity: 1 / 2 }} />
    ),
  },
  {
    label: 'Profile',
    linkTo: paths.dashboard.user.profile,
    icon: (
      <Iconify
        icon="solar:user-circle-bold-duotone"
        width={24}
        height={24}
        sx={{ opacity: 1 / 2 }}
      />
    ),
  },
  {
    label: 'My ads',
    icon: (
      <Iconify icon="solar:bill-list-bold-duotone" width={24} height={24} sx={{ opacity: 1 / 2 }} />
    ),
    linkTo: paths.adsManagement.root,
  },
  {
    label: 'Account Settings',
    linkTo: paths.user.account,
    icon: (
      <Iconify icon="solar:user-id-bold-duotone" width={24} height={24} sx={{ opacity: 1 / 2 }} />
    ),
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const smUp = useResponsive('up', 'sm');
  const lgDown = useResponsive('down', 'lg');
  const router = useRouter();
  const drawer = useBoolean();
  const { user } = useAuthContext();
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  const { enqueueSnackbar } = useSnackbar();
  const popover = usePopover();

  const handleLogout = async () => {
    try {
      popover.onClose();
      localStorage.setItem('authToken', '');
      removeCookie('authToken', { path: '/' });
      router.replace(paths.auth.login);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );
  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={drawer.onToggle}
        sx={{ width: 40, height: 40 }}
      >
        <Avatar
          src=""
          alt=""
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 320 },
        }}
      >
        {renderHead}

        <Stack sx={{ pl: 2.5, pr: 1, width: '100%' }} alignItems="center">
          <Stack sx={{ p: 2, width: '100%' }} alignItems="center" spacing={2}>
            <Avatar
              src={user?.photoURL}
              alt={user?.displayName}
              sx={{
                width: 100,
                height: 100,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            />

            <Stack sx={{ width: '100%' }} alignItems="center">
              <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
              <Typography>{user?.email}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack sx={{ mt: 2, width: '100%', height: '100%', pt: lgDown ? 5 : 0 }}>
          <Scrollbar
            sx={{
              height: '100%',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Stack sx={{ p: 1, rowGap: 1 }}>
              {OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => handleClickItem(option.linkTo)}
                  sx={{ px: 3, py: 1.5, display: 'flex', alignItems: 'center', columnGap: 2 }}
                >
                  {option.icon}
                  <Typography variant="subtitle2">{option.label}</Typography>
                </MenuItem>
              ))}
            </Stack>

            <Stack sx={{ width: '100%', px: 2, py: 5 }}>
              <Button onClick={(e) => handleLogout()} sx={{ py: 1 }} variant="soft" color="error">
                logout
              </Button>
            </Stack>
          </Scrollbar>
        </Stack>
      </Drawer>
    </>
  );
}
