import { gql, TypedDocumentNode } from '@apollo/client';

interface data {
  createMakeCompony: {
    success: boolean;
    message: string;
  };
}

export const CreateCarCompany: TypedDocumentNode<data> = gql`
  mutation CreateCarCompony($name: String!) {
    createMakeCompony(body: { name: $name }) {
      success
      message
    }
  }
`;
