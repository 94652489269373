import { gql, TypedDocumentNode } from '@apollo/client';

interface data {
  createMakeModel: {
    success: boolean;
    message: string;
  };
}

export const CreateCar: TypedDocumentNode<data> = gql`
  mutation CreateCarModel($name: String!, $compony: String!) {
    createMakeModel(body: { name: $name, compony: $compony }) {
      success
      message
    }
  }
`;
