import { forwardRef } from 'react';
// @MUI
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const settings = useSettingsContext();
    const isMini = settings.themeLayout === 'mini';
    const PRIMARY_LIGHT = theme.palette.primary.light;
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 'fit',
          height: 30,
          display: 'inline-flex',
          ...sx,
          marginBottom: '1.5rem',
        }}
        {...other}
      >
        {isMini ? (
          <img
            style={{ display: 'block', maxHeight: 20, margin: '0 auto' }}
            src="/assets/icons/sm-logo.png"
            alt="logo"
          />
        ) : (
          <img
            style={{ display: 'block', maxHeight: 30, margin: '0 auto' }}
            src="/assets/icons/lg-logo.png"
            alt="logo"
          />
        )}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
