/* eslint-disable no-nested-ternary */
import { useFormContext, Controller } from 'react-hook-form';
// @MUI
import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  zeroAllowed?: boolean;
};

export default function RHFTextField({ name, helperText, type, zeroAllowed, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={
            type === 'number' && field.value === '0'
              ? zeroAllowed
                ? parseInt(field.value, 10)
                : ''
              : field.value
          }
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(parseInt(event.target.value, 10));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
