import React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import { Card } from '@mui/material';
import CreateCarAdvertisementForm from "../components/create-car-advertisement-form";
import { useResponsive } from "../../../../hooks/use-responsive";

const CreateCarAdvertisement = () => {
    const mdUp = useResponsive('up', 'md');

    return (
        <Container maxWidth='md'>
            <Grid container spacing={3}>
                <Grid xs={ 12 }>
                    <Card sx={{ p: 2 }}>
                        { mdUp && (
                            <Grid md={ 4 }>
                                <Typography variant="h6" sx={ { mb: 2 } }>
                                    Create Car Advertisement
                                </Typography>
                            </Grid>
                        ) }

                        { !mdUp && <CardHeader title="Create Car Advertisement"/> }

                        <CreateCarAdvertisementForm />
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CreateCarAdvertisement;