import { DateObject } from 'react-multi-date-picker';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PersonFilterProps {
  date?: string | null;
  tags?: string | undefined;
}

const initialState: PersonFilterProps = {
  date: null,
  tags: undefined,
};

export const PersonFilterSlice = createSlice({
  name: 'personFilter',
  initialState,
  reducers: {
    addDateTime: (state, action: PayloadAction<PersonFilterProps>) => {
      state.date = action.payload.date;
    },
    addTagName: (state, action: PayloadAction<PersonFilterProps>) => {
      state.tags = action.payload.tags;
    },
  },
});

export const { addDateTime, addTagName } = PersonFilterSlice.actions;
export default PersonFilterSlice.reducer;