import { lazy } from 'react';
import { paths } from '../paths';
// LAYOUT
const AuthLayout = lazy(() => import('src/layouts/auth/auth-layout'));
// PAGES
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));

// ---------------------------------------------------------------------

export const authRoutes = [
  {
    path: paths.auth.login,
    element: (
      <AuthLayout title="Hi, Welcome back">
        <LoginPage />
      </AuthLayout>
    ),
  },
  {
    path: paths.auth.register,
    element: (
      <AuthLayout title="Welcome to Our Community">
        <RegisterPage />
      </AuthLayout>
    ),
  },
];
